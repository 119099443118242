import React, { useState } from 'react';
import './navigation.css';
import { Navbar, Nav, Image, Button, Container, Offcanvas } from 'react-bootstrap'; // , NavDropdown
import { Link, useLocation } from 'react-router-dom';
import useUser from '../hooks/useUser';
import logoHead from './pages/img/brandLogo.png';
import { HiOutlineSearch } from 'react-icons/hi';
import { HorizontalDivider } from './core';

const Navigation = () => {
	const location = useLocation();
	const { isLoggedIn, logout } = useUser();
	const [menuOpen, setMenuOpen] = useState(false);
	const toggleMenu = () => {
		setMenuOpen(!menuOpen);
	};
	const handleClose = () => setMenuOpen(false);

	const isActive = (path) => location.pathname === path;

	return (
		<>
			<Navbar collapseOnSelect sticky="top" expand="lg" bg="light" aria-label="Navigationsleiste">
				<Container fluid style={{ flexWrap: 'nowrap', alignItems: 'center', justifyContent: 'space-between' }}>
					<Navbar.Brand as={Link} to="/" style={{ flexShrink: 0 }}>
						<Image
							className="fixed-logo"
							src={logoHead}
							title="Logo der Fortbildung Berlin"
							alt="Logo der Fortbildung Berlin"
							style={{ height: '45px', width: 'auto' }}
						/>
					</Navbar.Brand>

					<Navbar.Toggle onClick={toggleMenu} aria-controls="Umschaltbare Navigationsleiste" className="d-lg-none" />

					<Navbar.Offcanvas
						id={'offcanvasNavbar-expand'}
						aria-label="Navigationsleiste anzeigen"
						placement="start"
						autoFocus={true}
						restoreFocus={true}
						keyboard={true}
						show={menuOpen}
						onHide={handleClose}
					>
						<Offcanvas.Header
							closeButton
							closeLabel="Schließen"
							title="Navigation auf einer kleinen Oberfläche"
						>
							<Offcanvas.Title id={'Titel-FORTBILDUNG-BERLIN'} title="Mobiles Menü">
								<Image
									className="fixed-logo"
									src={logoHead}
									title="Logo der Fortbildung Berlin"
									alt="Logo der Fortbildung Berlin"
									style={{ height: '45px', width: 'auto', flexShrink: 0 }}
								/>
							</Offcanvas.Title>
						</Offcanvas.Header>

						<Offcanvas.Body>
							<Nav className="justify-content-begin flex-grow-1 pe-3" as="ul">
								<li key="find">
									<Nav.Link
										className={`no-wrap ${isActive('/find') ? 'active-link' : ''}`}
										onClick={handleClose}
										as={Link}
										to="/find"
									>
										<strong>
											<HiOutlineSearch /> SUCHEN
										</strong>
									</Nav.Link>
								</li>
								<li key="schif">
									<Nav.Link
										className={`no-wrap ${isActive('/schif') ? 'active-link' : ''}`}
										onClick={handleClose}
										as={Link}
										to="/schif"
									>
										SchiF
									</Nav.Link>
								</li>
								<li key="conferences">
									<Nav.Link
										className={`no-wrap ${isActive('/conferences') ? 'active-link' : ''}`}
										onClick={handleClose}
										as={Link}
										to="/conferences"
									>
										Regional Konferenzen
									</Nav.Link>
								</li>
								<li key="symposiums">
									<Nav.Link
										className={`no-wrap ${isActive('/symposiums') ? 'active-link' : ''}`}
										onClick={handleClose}
										as={Link}
										to="/symposiums"
									>
										Fachtage
									</Nav.Link>
								</li>
								<li key="berufsschulen">
									<Nav.Link
										className={`no-wrap ${isActive('/berufsschulen') ? 'active-link' : ''}`}
										onClick={handleClose}
										as={Link}
										to="/berufsschulen"
									>
										Berufliche Schulen
									</Nav.Link>
								</li>
								<li key="leadership">
									<Nav.Link
										className={`no-wrap ${isActive('/leadership') ? 'active-link' : ''}`}
										onClick={handleClose}
										as={Link}
										to="/leadership"
									>
										Leadership
									</Nav.Link>
								</li>
								<li key="quali">
									<Nav.Link
										className={`no-wrap ${isActive('/quali') ? 'active-link' : ''} disabled-link`}
										onClick={handleClose} // Verhindert, dass der Link anklickbar ist
										as={Link}
										to="/quali"
										style={{ pointerEvents: 'none', color: 'gray' }} // Stilisiert den Link als inaktiv
									>
										Qualifizierung
									</Nav.Link>
								</li>

								<li key="extern">
									<Nav.Link
										className={`no-wrap ${isActive('/extern') ? 'active-link' : ''}`}
										onClick={handleClose}
										as={Link}
										to="/extern"
									>
										Externe
									</Nav.Link>
								</li>

								<li key="faq">
									<Nav.Link
										className={`no-wrap ${isActive('/faq/0') ? 'active-link' : ''}`}
										onClick={handleClose}
										as={Link}
										to="/faq/0"
									>
										FAQ
									</Nav.Link>
								</li>
							</Nav>
							<HorizontalDivider />

							{isLoggedIn ? (
								<div style={{ display: 'flex', flexWrap: 'nowrap' }}>
									<Button
										variant="outline-success"
										aria-label="Profildaten ändern"
										as={Link}
										onClick={handleClose}
										to="/profile"
									>
										Profil
									</Button>
									<Button variant="outline-danger mx-2" onClick={logout}>
										Logout
									</Button>
								</div>
							) : (
								<div style={{ display: 'flex', flexWrap: 'nowrap' }}>
									<Button
										variant="outline-success mx-2"
										as={Link}
										onClick={handleClose}
										to="/login"
										state={{ backgroundLocation: location }}
									>
										Login
									</Button>
									<Button
										variant="outline-dark mx-2"
										as={Link}
										onClick={handleClose}
										to="/register"
										state={{ backgroundLocation: location }}
									>
										Registrieren
									</Button>
								</div>
							)}
						</Offcanvas.Body>
					</Navbar.Offcanvas>
				</Container>
			</Navbar>
		</>
	);
};

export default Navigation;